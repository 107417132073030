import $ from 'jquery';
import './plugins/modernizr';
import './plugins/jquery-jvectormap-us-lcc';
import './plugins/jquery.fitvids';
import './plugins/externalLinks';
import '../sass/app.scss';
import Swiper from 'swiper';

// Make jQuery available to inline JS in the templates
global.$ = global.jQuery = $;

// Initialize FitVids on the main column
$('.column-main').fitVids();

// Toggle modal nav
$('[data-behavior=open-nav]').click(() => {
    $('body').addClass('nav-open');
});
$('[data-behavior=close-nav]').click(() => {
    $('body').removeClass('nav-open');
});

// Toggle modal nav items
$('[data-behavior=toggle-level-1]').click(function(event) {
    $(this)
        .parents('li')
        .siblings('li')
        .children('[data-behavior=toggle-level-1]')
        .removeClass('open');
    $(this).toggleClass('open');
    $(this)
        .parents('li')
        .siblings('li')
        .children('.nav-modal--level-1')
        .slideUp(200);
    $(this)
        .siblings('.nav-modal--level-1')
        .slideToggle(200);
    event.preventDefault();
});

// Initialize & confige jVectorMap
if (typeof window.states != 'undefined') {
    $('#map').vectorMap({
        map: 'us_lcc',
        zoomOnScroll: false,
        panOnDrag: false,
        zoomMax: 1,
        backgroundColor: '#fff',
        regionStyle: {
            initial: {
                fill: '#dcdcdc',
            },
            hover: {
                'fill-opacity': 1,
            },
            selected: {
                fill: '#d14646',
            },
            selectedHover: {
                fill: '#4d7a97',
                cursor: 'pointer',
            },
        },
        selectedRegions: Object.keys(window.states),
        onRegionTipShow: (event, tip, code) => {
            if (!window.states.hasOwnProperty(code)) {
                // Prevent tooltip display on unselected states
                return false;
            }
        },
        onRegionClick: (event, code) => {
            if (window.states.hasOwnProperty(code)) {
                window.location = window.states[code];
            }
        },
    });
}
if (typeof window.territories != 'undefined') {
    let territoriesList = '';
    $.each(window.territories, function(territory, info) {
        territoriesList += `<li><a href="${info.link}">${territory} <span>${info.name}</span></a></li>`;
    });
    $('#territories').append(territoriesList);
}

// Homepage featured stories slideshow
new Swiper('.swiper-container', {
    effect: 'fade',
    loop: true,
    autoplay: {
        delay: 7000,
        disableOnInteraction: true,
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
});
